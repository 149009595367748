<template>
    <ContainerVue>
        <p class="text-3xl text-center">Teachers Panel</p>

        <router-view/>

        <div class="relative mt-3">
            <div class="grid md:grid-cols-1 lg:grid-cols-3 gap-4 w-full">

                <TeacherItemVue v-for="teacher in teachers" :teacher="teacher"/>

            </div>
        </div>
    </ContainerVue>
</template>

<script>
    import { mapGetters } from 'vuex';
    import ContainerVue from '../../layouts/container.vue';
    import TeacherItemVue from './teacher-item.vue';

    export default {
        name: 'teacher',
        components: {
            ContainerVue,
            TeacherItemVue
        },
        data: {

        },
        computed: {
            ...mapGetters({
                teachers: 'teachers/teachers',
            }),
        },
        created() {
            this.$store.dispatch('teachers/teacherData');
        }
    }
</script>